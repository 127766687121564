import { v4 as uuidv4 } from "uuid";

function getDeviceInfo() {
  const deviceInfo = {
    user_agent: navigator.userAgent, // Información del navegador y el sistema operativo
    screen_resolution: `${window.screen.width}x${window.screen.height}`, // Resolución de pantalla
    device_category: "", // desktop, mobile o tablet
    browser: "", // Tipo de navegador
  };

  // Verifica si el navegador soporta `navigator.userAgentData`
  if (navigator.userAgentData) {
    const userAgentData = navigator.userAgentData;
    deviceInfo.device_category = userAgentData.mobile ? "mobile" : "desktop"; // Detecta móvil o escritorio
    deviceInfo.browser = userAgentData.brands
      .map((brand) => brand.brand)
      .join(", "); // Nombre del navegador
  } else {
    // Si `userAgentData` no está disponible, usa `userAgent` como fallback
    deviceInfo.device_category = /Mobi|Android/i.test(navigator.userAgent)
      ? "mobile"
      : "desktop";
  }

  return deviceInfo;
}

const getUserId = () => {
  let userId = localStorage.getItem("ga_user_id");
  if (!userId) {
    userId = uuidv4();
    localStorage.setItem("ga_user_id", userId);
  }
  return userId;
};

const getSessionId = () => {
  let sessionId = localStorage.getItem("ga_session_id");
  if (!sessionId) {
    sessionId = uuidv4();
    localStorage.setItem("ga_session_id", sessionId);
  }
  return sessionId;
};

export const gaEvent = async ({ name = "custom_event", params = {} }) => {
  const queryParams = new URL(document.location.toString()).searchParams;
  const utm_source = queryParams.get("utm_source") || "NOT_SET";
  const utm_content = queryParams.get("utm_content") || "NOT_SET";
  const utm_medium = queryParams.get("utm_medium") || "qr";
  const utm_campaign = queryParams.get("utm_campaign") || "campaign1";
  const deviceInfo = getDeviceInfo();

  const payload = {
    client_id: getUserId(),
    user_id: getUserId(),
    session_id: getSessionId(),
    name,
    params: {
      page_path: window.location.pathname,
      utm_source,
      utm_content,
      utm_medium,
      utm_campaign,
      ...params,
      ...deviceInfo,
    },
  };
  const url = `${process.env.REACT_APP_API}/send-ga4-event/`;
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const gaVirtualPageview = (path) => {
  gaEvent({
    name: "page_view",
    params: {
      page_path: path,
    },
  });
};
