import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import useStories from "../../../../models/stories/useStories";

import "./styles.scss";

const EmailModal = ({ story, onClose }) => {
  const { sendDeliveryEmail } = useStories();

  return (
    <Dialog
      open={true}
      onClose={onClose}
      className="EmailModal"
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();
          try {
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            await sendDeliveryEmail(story.id, { email });
            console.log(email);
            toast.success(`Email enviado correctamente`);
            onClose();
          } catch (e) {
            toast.error("UPS, no hemos podido enviar el email.");
          }
        },
      }}
    >
      <DialogContent>
        <DialogContentText>
          <div className="EmailModal__title">¿Cuál es tu email?</div>
          <div className="EmailModal__subtitle">
            En el email te envíaremos las instrucciones para canjear tu regalo.
          </div>
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="email"
          type="email"
          fullWidth
          placeholder="Dirección de email"
        />
      </DialogContent>
      <DialogActions>
        <button type="submit">Enviar</button>
      </DialogActions>
    </Dialog>
  );
};
export default EmailModal;
